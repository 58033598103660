import React, { useEffect, useState } from "react";
import tw, { styled } from "twin.macro";

const Contact = styled.section`
  ${tw`py-10 md:py-20 lg:py-32`}

  p {
    ${tw`text-lg leading-relaxed`}
  }

  h6 {
    ${tw`font-bold`}
  }

  h5 {
    ${tw`mt-6 mb-1 text-2xl leading-tight`}
  }

  .right-side {
    p {
      ${tw`text-base`}
    }
  }
`;

const ContactUs = ({ content }) => {
  const [mainCopy, setMain] = useState(null);
  const [additionalCopy, setAdditional] = useState(null);

  useEffect(() => {
    // This checks to see if there is a "Read More" tag from Wordpress
    // If the tag is found, we will split the content in two
    // we'll use the "extended" state to toggle the Additional copy
    if (content.includes("<!--more-->")) {
      setMain(content.split("<!--more-->")[0]);
      setAdditional(content.split("<!--more-->")[1]);
    } else {
      setMain(content);
    }
    return () => {};
  }, [content]);

  return (
    <Contact className="container" id="contact">
      <div className="flex flex-wrap md:-mx-4">
        <div className="w-full md:w-1/2 md:px-4">
          <div className="md:max-w-sm" dangerouslySetInnerHTML={{ __html: mainCopy }}></div>
        </div>
        <div className="w-full md:w-1/2 md:px-4">
          <div className="right-side" dangerouslySetInnerHTML={{ __html: additionalCopy }}></div>
        </div>
      </div>
    </Contact>
  );
};

export default ContactUs;
