import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import tw, { styled } from "twin.macro";
// import classNames from "classnames";
import Layout from "../components/Layout/Layout";
import SEO from "../components/seo";
import PageHero from "../components/PageSections/PageHero";
import LibraryList from "../components/PageSections/LibraryList";
import GetInvolved from "../components/PageSections/GetInvolved";
import ContactUs from "../components/PageSections/ContactUs";

const LibraryContainer = styled.main`
  ${tw`
    py-24 px-5
  `};
  background-color: #ffffff;
  color: var(--color--offBlack);

  @media (min-width: 768px) {
    padding: 99px 118px;
  }

  @media (min-width: 1024px) {
    padding: 120px 180px;
  }
`;

const LibraryPage = () => {
  const data = useStaticQuery(graphql`
    {
      contact: allWordpressPage(filter: { slug: { eq: "about" } }) {
        nodes {
          acf {
            contact
          }
        }
      }

      hero: allWordpressPage(filter: { slug: { eq: "library" } }) {
        nodes {
          slug
          status
          template
          title
          acf {
            hero {
              art {
                source_url
              }
              artist {
                credit
                age
              }
              title
              copy
              cta_type
              cta_link
              cta_text
            }
          }
          yoast_meta {
            yoast_wpseo_social_defaults {
              og_frontpage_image {
                source_url
              }
            }
            yoast_wpseo_metadesc
            yoast_wpseo_facebook_title
            yoast_wpseo_facebook_image {
              source_url
            }
            yoast_wpseo_facebook_description
            yoast_wpseo_twitter_title
            yoast_wpseo_twitter_image {
              source_url
            }
            yoast_wpseo_twitter_description
          }
        }
      }
      links: allWordpressWpLibrary {
        nodes {
          title
          id
          acf {
            external_link
            external_source
          }
          topic
          modified(locale: "en-us")
        }
      }
      topics: allWordpressWpTopic {
        nodes {
          name
          id
          wordpress_id
        }
      }
    }
  `);

  const { yoast_meta, title } = data?.hero?.nodes[0];
  const { hero } = data?.hero?.nodes[0].acf;
  const { contact } = data?.contact?.nodes[0].acf;

  const {
    yoast_wpseo_metadesc: seo_desc,
    yoast_wpseo_facebook_title: seo_fb_title,
    yoast_wpseo_facebook_description: seo_fb_desc,
    yoast_wpseo_twitter_title: seo_tw_title,
    yoast_wpseo_twitter_description: seo_tw_desc
  } = yoast_meta;

  const seo_image =
    yoast_meta.yoast_wpseo_social_defaults?.og_frontpage_image?.source_url ||
    "";
  const seo_fb_image =
    yoast_meta.yoast_wpseo_facebook_image?.source_url || seo_image;
  const seo_tw_image =
    yoast_meta.yoast_wpseo_twitter_image?.source_url || seo_image;

  const [topics] = useState(data?.topics?.nodes);
  const [links] = useState(data?.links?.nodes);

  return (
    <Layout>
      <SEO
        title={title}
        image={seo_fb_image}
        description={seo_desc}
        metaTitleFacebook={seo_fb_title}
        metaImageFacebook={seo_fb_image}
        metaDescFacebook={seo_fb_desc}
        metaTitleTwitter={seo_tw_title}
        metaImageTwitter={seo_tw_image}
        metaDescTwitter={seo_tw_desc}
      />

      <PageHero {...hero} />
      <LibraryContainer className="section-border">
        <LibraryList topics={topics} links={links} />
      </LibraryContainer>
      <ContactUs content={contact} />
      <GetInvolved />
    </Layout>
  );
};

export default LibraryPage;
