import React from "react";
import { styled } from "twin.macro";
import { Link } from "gatsby";
import Button from "../PageComponents/Button";

const GetInvolved = styled.section`
  background-color: var(--color-magenta);
  color: var(--color-offWhite);
  text-align: center;

  padding: 60px 48px;

  @media (min-width: 768px) {
    padding: 90px 118px;
  }
`;

const Title = styled.h4`
  font-weight: 600;

  font-size: 40px;
  line-height: 48px;
  letter-spacing: -1.83px;
  margin-bottom: 5px;

  @media (min-width: 768px) {
    font-size: 55px;
    line-height: 55px;
    letter-spacing: -2.51px;
    margin-bottom: 10px;
  }

  @media (min-width: 1024px) {
    font-size: 80px;
    line-height: 96px;
    letter-spacing: -3.27px;
  }
`;

const Copy = styled.p`
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.27px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 19px;
    line-height: 29px;
    letter-spacing: -0.36px;
    margin-bottom: 35px;
  }

  @media (min-width: 1024px) {
    font-size: 22px;
    line-height: 34px;
    letter-spacing: -0.41px;
    margin-bottom: 45px;
  }
`;

const GetInvolvedComponent = () => {
  return (
    <GetInvolved>
      <Title>Get Involved</Title>
      <Copy>Want to see how you can help? Learn more and get started now.</Copy>
      <Link to="/get-involved/">
        <Button style="light">See How</Button>
      </Link>
    </GetInvolved>
  );
};

export default GetInvolvedComponent;
