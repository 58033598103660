import React from "react";
import tw, { styled } from "twin.macro";
import FillerFallback from "../../images/hero_filler_one.png";
// import Button from "../PageComponents/Button";

const PageHero = styled.section`
  position: relative;

  &.page-gallery {
    .hero-text { ${tw`md:flex md:flex-col md:justify-center`} }
    .hero-title { ${tw`text-4xl sm:text-5xl lg:text-6xl`} }
    .hero-copy { ${tw`font-bold mt-4 text-base sm:text-2xl lg:text-3xl`} }

    @media(min-width: 1348px) {
      .hero-title { ${tw`text-7xl`} }
    }
  }
`;

const InnerContent = styled.aside`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 30px 50px 60px;
  // border-bottom: 1px solid rgba(124, 135, 149, 0.3);

  &.get-involved {
    padding: 30px 50px 100px;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    padding: 90px 64px 90px 84px;
    justify-content: space-between;

    &.get-involved {
      padding: 90px 64px 120px 84px;
    }
  }

  @media (min-width: 1024px) {
    padding: 55px 120px;
    max-width: 1440px;
    margin: 0 auto;

    &.get-involved {
      padding: 55px 120px 120px 120px;
    }
  }
`;

const GreyBackground = styled.div`
  position: absolute;
  background-color: #f3f4f5;
  z-index: -1;

  height: 194px;
  top: 0;
  left: 0;
  right: 0;

  @media (min-width: 768px) {
    bottom: 0;
    width: 200px;
    height: auto;
  }

  @media (min-width: 1024px) {
    width: 31.739%;
  }
`;

const ArtistCredit = styled.aside`
  display: flex;
  justify-content: space-between;
  margin: 0 0 20px;
  align-items: center;
  color: var(--color-grey);

  @media (min-width: 768px) {
    position: absolute;
    left: 64px;
    position: absolute;
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(90deg);
    transform: rotate(-90deg);
    transform-origin: 0 0;
    width: 200px;
    top: 75%;

    &.get-involved {
      top: 63%;
    }
  }

  @media (min-width: 1024px) {
    left: 10px;
    padding: 55px 0 0;
    width: 330px;
    top: 85%;
    &.get-involved {
      top: 72%;
    }
  }
`;

const ArtistCopy = styled.p`
  margin: 0;
  font-family: attribute-mono, monospace;
  font-weight: 300;
  font-size: 12px;
  line-height: 17px;
  text-transform: uppercase;
  width: 20%;

  @media (min-width: 768px) {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.11px;
    width: auto;
  }

  @media (min-width: 1024px) {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5px;
    width: auto;
    &:last-of-type {
      padding-left: 20px;
    }
  }
`;

const Line = styled.div`
  height: 2px;
  background-color: #979797;
  background-position: center;
  width: 100%;
  width: -webkit-fill-available;
  margin: 0 15px;

  @media (min-width: 768px) {
    width: 1px;
    height: 100%;
    margin: 15px 0;
  }

  @media (min-width: 768px) {
    width: calc(60% - 60px);
    height: 2px;
  }
`;

const HeaderImage = styled.aside`
  height: 200px;
  width: 245px;
  background-position: center;
  background-size: contain;
  background-image: ${props => `url(${props.bg})`};
  background-repeat: no-repeat;
  margin: 0 auto 30px;

  @media (min-width: 768px) {
    margin: 0;
  }

  @media (min-width: 1024px) {
    width: 422px;
    height: 353px;
  }
`;

const Image = styled.img`
  max-width: 100%;
  width: 100%;
  opacity: 0;
  visibility: hidden;
`;

const HeroText = styled.div`
  text-align: center;
  @media (min-width: 768px) {
    text-align: left;
    width: 58.33333333%;
  }
`;

const HeroTitle = styled.h2`
  font-weight: 600;
  font-size: 40px;
  line-height: 1;
  letter-spacing: -0.83px;
  margin-bottom: 5px;

  @media (min-width: 768px) {
    font-size: 55px;
    letter-spacing: -2.51px;
  }

  @media (min-width: 1024px) {
    font-size: 90px;
    letter-spacing: -4.11px;
    margin-bottom: 10px;
  }
`;

const HeroCopy = styled.p`
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.27px;
  max-width: 32ch;
  margin: 0 auto 24px;

  @media (min-width: 768px) {
    font-size: 19px;
    line-height: 29px;
    letter-spacing: -0.36px;
    margin: 0 0 28px;
    max-width: 42ch;
  }

  @media (min-width: 1024px) {
    font-size: 22px;
    line-height: 34px;
    letter-spacing: -0.41px;
    margin-top: 2rem;
  }
`;

const PageHeroComponent = ({
  art,
  artist,
  title,
  copy,
  cta_type,
  cta_text,
  cta_link,
  getInvolved,
  page,
}) => {
  return (
    <PageHero className={`section-border page-hero page-${page ?? 'default'}`}>
      <GreyBackground />
      <InnerContent className={getInvolved ? "get-involved" : ""}>
        <ArtistCredit className={getInvolved ? "get-involved" : ""}>
          <ArtistCopy>{artist?.credit}</ArtistCopy>
          <Line />
          <ArtistCopy>{`Age ${artist?.age}`}</ArtistCopy>
        </ArtistCredit>
        <HeaderImage bg={art?.source_url || FillerFallback}>
          <Image src={art?.source_url || FillerFallback} alt={title} />
        </HeaderImage>
        <HeroText className="hero-text">
          <HeroTitle className="hero-title">{title}</HeroTitle>
          <HeroCopy className="hero-copy">{copy}</HeroCopy>
          {/* <Button style={"minimal_arrow_diagonal"}>{cta_text}</Button> */}
        </HeroText>
      </InnerContent>
    </PageHero>
  );
};

export default PageHeroComponent;
