import React, { useState } from "react";
import { styled } from "twin.macro";
import classNames from "classnames";
import HTMLDecoderEncoder from "html-encoder-decoder";
import { ReactComponent as Search } from "../../images/ICON_GREY_SEARCH.svg";
import { ReactComponent as Arrow } from "../../images/ICON_GREY_ARROWRIGHT.svg";
import { ReactComponent as Close } from "../../images/ICON_GREY_CLOSE.svg";
import TopicDrawer from "./LibraryListItemDrawer";

const LibraryContainer = styled.section`
  margin: 0 auto;
`;

const Library = styled.section`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

const SearchForm = styled.form`
  display: flex;
  align-items: baseline;
  border-bottom: 2px solid var(--color-offBlack);
  position: relative;
  padding-bottom: 5px;

  @media (min-width: 768px) {
  }

  @media (min-width: 1024px) {
    margin-bottom: 90px;
  }
`;
const Label = styled.label`
  visibility: hidden;

  svg {
    visibility: visible;
    height: 20px;
    width: 20px;

    path {
      fill: var(--color-offBlack);
    }
  }

  @media (min-width: 768px) {
    svg {
      height: 30px;
      width: 30px;
    }
  }

  @media (min-width: 1024px) {
    svg {
      height: 40px;
      width: 40px;
    }
  }
`;

const Input = styled.input`
  &:focus {
    outline: 1px solid transparent;
  }
  color: var(--color-offBlack);

  &::placeholder {
    opacity: 0.3;
  }

  width: 100%;
  padding-left: 15px;
  font-size: 25px;
  letter-spacing: -0.6px;

  @media (min-width: 768px) {
    font-size: 30px;
    line-height: 38px;
    letter-spacing: -0.8px;
  }

  @media (min-width: 1024px) {
    font-size: 43px;
    line-height: 66px;
    letter-spacing: -1.1px;
  }
`;

const Submit = styled.button`
  margin-left: auto;

  svg {
    height: 20px;
    width: 20px;
    path {
      fill: var(--color-offBlack);
    }
  }

  &:focus {
    outline: 1px solid transparent;
  }

  @media (min-width: 768px) {
    svg {
      height: 30px;
      width: 30px;
    }
  }

  @media (min-width: 1024px) {
    svg {
      height: 40px;
      width: 40px;
    }
  }
`;

const ListTitle = styled.h3`
  font-size: 25px;
  line-height: 30px;
  letter-spacing: -0.6px;

  @media (min-width: 768px) {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -1px;
  }

  @media (min-width: 1024px) {
    font-size: 43px;
    line-height: 52px;
  }
`;

const TopicList = styled.ul`
  @media (min-width: 1024px) {
    width: 50%;
    padding-right: 25px;
  }
`;

const TopicListItem = styled.li`
  @media (min-width: 1024px) {
    margin-bottom: 20px;
  }
`;

const TopicTitle = styled.h4`
  font-weight: 800;
  color: var(--color-grey);
  cursor: pointer;
  transition: color 400ms cubic-bezier(0.12, 0.67, 0.53, 1);

  &.active,
  &:hover {
    cursor: pointer;
    color: var(--color-magenta);
    text-decoration: underline;
  }

  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.4px;

  @media (min-width: 768px) {
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.6px;
  }

  @media (min-width: 1024px) {
    font-size: 27px;
    line-height: 32px;
    transition: opacity 0.25s ease;

    &:hover {
      opacity: 0.6;
    }
  }
`;

const RightContainer = styled.section`
  position: relative;
  transition: opacity 0.25s ease;

  &.transitioning {
    opacity: 0;
  }
  @media (min-width: 1024px) {
    width: 50%;
  }
`;

const ActiveTopicList = styled.ul`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  pointer-events: none;

  &.active {
    pointer-events: all;
    position: relative;
    opacity: 1;
    animation-name: fadeIn;
    animation-duration: 0.5s;

    @keyframes fadeIn {
      from {
        top: -10px;
        opacity: 0;
      }

      to {
        top: 0;
        opacity: 1;
      }
    }
  }

  display: none;
  @media (min-width: 1024px) {
    padding-left: 25px;
    display: block;
  }
`;

const SearchResults = styled.ul`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  pointer-events: none;

  &.active {
    pointer-events: all;
    position: relative;
    opacity: 1;
    animation-name: fadeIn;
    animation-duration: 0.5s;

    @keyframes fadeIn {
      from {
        top: -10px;
        opacity: 0;
      }

      to {
        top: 0;
        opacity: 1;
      }
    }
  }
  @media (min-width: 1024px) {
    padding-left: 25px;
  }
`;

const MostRecentList = styled.ul`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  pointer-events: none;

  &.active {
    pointer-events: all;
    position: relative;
    opacity: 1;
    animation-name: fadeIn;
    animation-duration: 0.5s;

    @keyframes fadeIn {
      from {
        top: -10px;
        opacity: 0;
      }

      to {
        top: 0;
        opacity: 1;
      }
    }
  }
  @media (min-width: 1024px) {
    display: block;
    padding-left: 25px;
  }
`;

const Results = styled.p`
  font-size: 12px;
  line-height: 14px;
  font-weight: 800;

  span.value {
    color: var(--color-magenta);
  }
  margin-bottom: 15px;

  @media (min-width: 768px) {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.1px;
  }

  @media (min-width: 1024px) {
    padding-top: 10px;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.2px;
  }
`;

const TopicLink = styled.a`
  text-decoration: none;
  color: inherit;
`;

const Topic = styled.li`
  margin-bottom: 15px;

  &:last-of-type {
    margin-bottom: 30px;
  }
`;

const Headline = styled.p`
  font-weight: 800;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.1px;
  margin-bottom: 5px;

  @media (min-width: 768px) {
    font-size: 19px;
    line-height: 23px;
    letter-spacing: -0.2px;
  }

  @media (min-width: 1024px) {
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.6px;
  }
`;

const Source = styled.p`
  font-family: attribute-mono;
  text-transform: uppercase;
  color: var(--color-magenta);
  margin: 0;

  font-size: 14px;
  line-height: 20px;

  @media (min-width: 768px) {
    font-size: 17px;
    line-height: 24px;
    letter-spacing: -0.2px;
  }

  @media (min-width: 1024px) {
    font-size: 17px;
    line-height: 24px;
    letter-spacing: -0.2px;
  }
`;

const LibraryList = ({ topics, links }) => {
  const [searchActive, setSearchActive] = useState(false);
  const [filterActive, setFilterActive] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [filterResults, setFilterResults] = useState([]);
  const [activeTopic, setActiveTopic] = useState(null);
  const [topicLists] = useState(
    topics.map(topic => {
      const topicLinks = links.filter(link =>
        link.topic.includes(topic.wordpress_id)
      );

      return {
        id: topic.wordpress_id,
        title: topic.name,
        links: topicLinks
      };
    })
  );
  const [mostRecentLinks] = useState(
    links.sort((a, b) => (a.modified < b.modified ? 1 : -1)).slice(0, 10)
  );

  const handleSearch = event => {
    event.preventDefault();

    const { value } = event.target.topic ? event.target.topic : event.target;

    setSearchValue(value);
    setActiveTopic(null);

    if (value.length >= 3) {
      setSearchActive(true);

      const results = links.filter(link =>
        link.title.toLowerCase().includes(value.toLowerCase())
      );

      results.length > 0 && setSearchResults(results);
    } else {
      setSearchActive(false);
      setSearchResults([]);
    }
  };

  const setFade = bool => {
    const slideContent = document.querySelector(".topics");
    if (bool) {
      slideContent.classList.add("transitioning");
    } else {
      setTimeout(() => {
        slideContent.classList.remove("transitioning");
      }, 500);
    }
  };

  const handleClickTopic = event => {
    setFade(true);
    const value = event.target.innerText;

    const newTopic = topicLists.find(item => item.title === value);

    newTopic &&
      setTimeout(() => {
        setSearchActive(false);
        setSearchResults([]);
        const input = document.getElementById("topic");
        input.value = "";
        setActiveTopic(topicLists.find(item => item.title === value));
      }, 500);

    setFade(false);

    //
    // Previous ANDing of filter & search
    //

    // if (searchResults.length > 0) {
    //   // if there is an existing search
    //   // const topicID = topicLists.find(topic => topic.title === value)?.id;

    //   // const newResults = searchResults.filter(item =>
    //   //   item.topic.includes(topicID)
    //   // );
    //   // if (newResults.length > 0) {
    //   //   setFilterResults(newResults);
    //   //   setFilterActive(true);
    //   // } else {
    //   //   setFilterResults([]);
    //   // }
    //   setFilterResults([]);
    //   setSearchValue(null);
    // } else {
    //   setFilterActive(false);
    // }
  };

  return (
    <LibraryContainer className="container">
      <SearchForm className="mb-16" onSubmit={handleSearch}>
        <Label htmlFor="topic" aria-label="Search Topic">
          <Search />
        </Label>
        <Input
          type="text"
          name="topic"
          id="topic"
          placeholder="Search"
          onChange={handleSearch}
        />
        <Submit type="submit" className="library">
          {searchActive ? (
            <Close
              onClick={e => {
                e.preventDefault();
                setSearchActive(false);
                setSearchValue(null);
                setSearchResults([]);
                const input = document.getElementById("topic");
                input.value = "";
              }}
            />
          ) : (
            <Arrow />
          )}
        </Submit>
      </SearchForm>
      <Library>
        <TopicList>
          <li>
            <ListTitle className="mb-5">Topics</ListTitle>
          </li>
          {topicLists.map(topic => (
            <TopicListItem key={topic.id}>
              <TopicTitle
                className={classNames({
                  active: topic.title === activeTopic?.title
                })}
                onClick={handleClickTopic}
              >
                {topic.title}
              </TopicTitle>
              <TopicDrawer expanded={topic.title === activeTopic?.title}>
                <Results>{topic.links.length} Results</Results>
                {topic.links.map(link => (
                  <Topic key={link.id}>
                    <TopicLink
                      href={link.acf.external_link}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <Headline>
                        {HTMLDecoderEncoder.decode(link.title)}
                      </Headline>
                      <Source>{link.acf.external_source}</Source>
                    </TopicLink>
                  </Topic>
                ))}
              </TopicDrawer>
            </TopicListItem>
          ))}
        </TopicList>
        <RightContainer className="topics">
          <ActiveTopicList
            className={classNames({
              active: !searchActive
            })}
          >
            {activeTopic && (
              <React.Fragment>
                <Results>{activeTopic.links.length} Results</Results>
                {activeTopic.links.map(link => (
                  <Topic key={link.id}>
                    <TopicLink
                      href={link.acf.external_link}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <Headline>
                        {HTMLDecoderEncoder.decode(link.title)}
                      </Headline>
                      <Source>{link.acf.external_source}</Source>
                    </TopicLink>
                  </Topic>
                ))}
              </React.Fragment>
            )}
          </ActiveTopicList>

          <MostRecentList
            className={classNames({
              active: !activeTopic && !searchActive
            })}
          >
            <Results>Most recent articles</Results>
            {mostRecentLinks.map(link => (
              <Topic key={link.id}>
                <TopicLink
                  href={link.acf.external_link}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Headline>{HTMLDecoderEncoder.decode(link.title)}</Headline>
                  <Source>{link.acf.external_source}</Source>
                </TopicLink>
              </Topic>
            ))}
          </MostRecentList>

          <SearchResults
            className={classNames({
              active: searchActive || searchResults.length > 0
            })}
          >
            <li>
              <Results>
                Search: <span className="value">{searchValue}</span>
                {filterActive && activeTopic && (
                  <React.Fragment>
                    <br /> Filter:{" "}
                    <span className="value">{activeTopic?.title}</span>
                  </React.Fragment>
                )}
                <br /> {filterResults.length || searchResults.length} Results
              </Results>
            </li>
            {filterResults.length > 0 ? (
              <React.Fragment>
                {filterResults.map(result => (
                  <Topic key={result.id}>
                    <TopicLink
                      href={result.acf.external_link}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <Headline>
                        {HTMLDecoderEncoder.decode(result.title)}
                      </Headline>
                      <Source>{result.acf.external_source}</Source>
                    </TopicLink>
                  </Topic>
                ))}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {searchResults.map(result => (
                  <Topic key={result.id}>
                    <TopicLink
                      href={result.acf.external_link}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <Headline>
                        {HTMLDecoderEncoder.decode(result.title)}
                      </Headline>
                      <Source>{result.acf.external_source}</Source>
                    </TopicLink>
                  </Topic>
                ))}
              </React.Fragment>
            )}
          </SearchResults>
        </RightContainer>
      </Library>
    </LibraryContainer>
  );
};

export default LibraryList;
