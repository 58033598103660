import React, { useRef, useEffect, useState } from "react";
import { styled } from "twin.macro";
import classNames from "classnames";

const DrawerWrapper = styled.div`
  height: 0;
  overflow: hidden;
  transition: height 500ms cubic-bezier(0.12, 0.67, 0.53, 1),
    opacity 500ms cubic-bezier(0.12, 0.67, 0.53, 1);

  &.expanded {
    height: ${props => `${props.refHeight}px`};
    padding-bottom: 20px;
  }

  @media (min-width: 1024px) {
    display: none;
  }
`;

const TopicDrawer = styled.ul`
  padding-bottom: 20px;
`;

const LibraryListItemDrawer = ({ expanded, children }) => {
  const drawerRef = useRef();

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <DrawerWrapper
      refHeight={drawerRef?.current?.getBoundingClientRect().height}
      className={classNames({ expanded })}
    >
      <TopicDrawer ref={drawerRef}>{children}</TopicDrawer>
    </DrawerWrapper>
  );
};
export default LibraryListItemDrawer;
